<script>
import Breadcrumb from 'primevue/breadcrumb';

export default {
  name: "AkBreadcrumb",
  components: {Breadcrumb},
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    model: {
      type: Array,
      required: true
    },
    goTo: {
      type: Function,
      required: true
    }
  },
}
</script>

<template>
  <Breadcrumb v-if="!loading" :home="{ icon: 'pi pi-home', id: null }" :model="model" style="border: none; padding-top: 0 !important;">
    <template #item="{ item }">
      <div @click="goTo(item.id)" style="cursor: pointer;">
        <span :class="[item.icon, 'text-color']" />
        <span class="text-primary font-semibold">{{ item.name }}</span>
      </div>
    </template>
  </Breadcrumb>
</template>

<style scoped>

</style>